import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Link,
  ListItem,
  Spacer,
  Stack,
  Tag,
  Text,
  TextProps,
  UnorderedList,
  useBreakpointValue,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { RETURN_ITEMS_URL_PROD, RETURN_ITEMS_URL_STAGING } from 'composable/components/general/constants'
import { SummaryLineItem } from 'composable/components/summary-line-item'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { BsShop, BsTruck } from 'react-icons/bs'
import { IoPrint } from 'react-icons/io5'
import { getOrderDetails } from 'frontastic/actions/advancedOrderSearch'
import { useAccountCT } from 'frontastic/contexts'
import OrderItems from './order-item'
interface Props {
  orderId: string
  backToHistoryPage: () => void
}
const OrderDetails = (props: any) => {
  const [orderDetails, setOrderDetails] = useState<any>()
  const [billingAddress, setBillingAddress] = useState<any>()
  const router = useRouter()
  let orderId = router.query?.orderId ? router?.query?.orderId : props?.orderId
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [loader, setLoader] = useState<any>(true)
  const { loggedIn } = useAccountCT()

  useEffect(() => {
    if (orderId) {
      getDetails({ orderId: orderId })
    }
  }, [])

  const fulfillmentStatus = orderDetails?.rootData?.FulfillmentStatus
  let isReturnItemsEnable = true
  if (['Canceled'].includes(fulfillmentStatus)) {
    isReturnItemsEnable = false
  }

  const getDetails = async (payload: any) => {
    const response = await getOrderDetails(payload)
    setLoader(false)
    if (response) {
      setOrderDetails(response)
      setBillingAddress(response?.billingAddress)
      getQuantity()
    }
  }

  const getQuantity = () => {
    let qty = 0
    orderDetails?.items?.forEach((obj) => {
      qty += obj.quantity
    })

    return (
      <Box display={'flex'} flexDirection={'row'} fontSize="14px" fontStyle="normal" fontWeight={400}>
        <Text width={'124px'} color={'var(--semantic-text-muted, #76797E)'}>
          Total Item(s)
        </Text>
        <Text color={'var(--semantic-text-primary, #15191C)'} lineHeight="21px">
          {qty}
        </Text>
      </Box>
    )
  }

  const BreadCrumb = () => {
    return (
      <Breadcrumb
        pl={{ base: 4, md: props?.orderId ? 4 : 10 }}
        pt={'10px'}
        gap="var(--sizes-2, 8px)"
        color="var(--semantic-text-muted,#76797E)"
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="/account/dashboard">My Account</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="/account/dashboard#orders">Order History</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Order#{orderId ?? orderId}</Text>
        </BreadcrumbItem>
      </Breadcrumb>
    )
  }

  const getPaymentDetails = (data: any) => {
    const paymentType = data?.paymentType ?? ''
    if (paymentType == 'Credit Card') {
      return (
        <Box>
          <Text fontSize={'sm'}> {data?.displayName?.replace('ending in', ': XXXX-')} </Text>
          <Text fontSize={'sm'}>
            Expiry: {data?.cardExpiryMonth}/{data?.cardExpiryYear?.slice(-2)}
          </Text>
        </Box>
      )
    } else if (paymentType == 'Gift Card') {
      return (
        <Box>
          <Text fontWeight={500} fontSize={'sm'}>
            {data?.paymentType}
          </Text>
          <Text fontSize={'sm'}> {data?.displayName} </Text>
        </Box>
      )
    } else {
      return (
        <Box>
          <Text fontWeight={500} fontSize={'sm'}>
            {data?.paymentType}
          </Text>
        </Box>
      )
    }
  }

  const OrderPlacedDate = () => {
    const originalDate = orderDetails?.rootData?.CreatedTimestamp
    const dateObj = new Date(originalDate)
    const year = dateObj.getFullYear()

    const formattedDate =
      new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      })
        ?.format(dateObj)
        .replace(',', '') + ' EDT'

    return (
      <>
        <Box display={'flex'} flexDirection={'row'} fontSize="14px" fontStyle="normal" fontWeight={400}>
          <Text width={'124px'} color={'var(--semantic-text-muted, #76797E)'}>
            Order Placed on
          </Text>
          <Text color={'var(--semantic-text-primary, #15191C)'} lineHeight="21px">
            {formattedDate}
          </Text>
        </Box>
      </>
    )
  }

  const { formatMessage } = useFormat({ name: 'common' })

  const handleGoForward = () => {
    router.push(props?.orderId ? 'account/dashboard#orders' : '')
  }

  const createReturlUrl: any = () => {
    let returnUrl = ''
    if (orderDetails && orderDetails?.rootData && orderDetails?.rootData?.OrderToken) {
      let host = window.location.href
      if (host && host.indexOf('cofe.dxl.com') > -1) {
        returnUrl = RETURN_ITEMS_URL_STAGING.replace('${orderId}', orderId)
          .replace('${OrderToken}', orderDetails?.rootData?.OrderToken)
          .replace('${OrgId}', orderDetails?.rootData?.OrgId)
      } else if (host && host.indexOf('dxl.com') > -1) {
        returnUrl = RETURN_ITEMS_URL_PROD.replace('${orderId}', orderId)
          .replace('${OrderToken}', orderDetails?.rootData?.OrderToken)
          .replace('${OrgId}', orderDetails?.rootData?.OrgId)
      } else {
        returnUrl = RETURN_ITEMS_URL_STAGING.replace('${orderId}', orderId)
          .replace('${OrderToken}', orderDetails?.rootData?.OrderToken)
          .replace('${OrgId}', orderDetails?.rootData?.OrgId)
      }
      return returnUrl
    }
  }

  const getItemsGroupByStores = () => {
    if (orderDetails?.PickUpAtStore?.length > 0) {
      return Object.entries(
        orderDetails?.PickUpAtStore?.reduce((acc: any, item: any) => {
          const city = item?.deliveryAddress?.City
          if (!acc[city]) acc[city] = []
          acc[city].push(item)
          return acc
        }, {}),
      )
    }
  }

  const NoOrderFound = () => {
    return (
      <Box
        pl={{ base: 4, md: props?.orderId ? 4 : 10 }}
        pr={{ base: 4, md: props?.orderId ? 4 : '100px' }}
        pt={{ base: props?.orderId ? 2 : 10, md: props?.orderId ? '0px' : 12 }}
        pb={{ base: 10, md: props?.orderId ? '0px' : '80px' }}
      >
        <Box h="100px">
          {loader && (
            <Button
              isLoading
              loadingText="Loading.."
              colorScheme="black"
              variant="link"
              spinnerPlacement="start"
            ></Button>
          )}
          {!loader && (
            <VStack alignItems={'flex-start'}>
              <Heading fontSize="28px" fontWeight={700}>
                Order #{orderId ?? orderId}
              </Heading>
              <Text>No Order Found.</Text>
            </VStack>
          )}
        </Box>
      </Box>
    )
  }

  if (orderDetails && !orderDetails?.rootData) {
    return <NoOrderFound></NoOrderFound>
  }

  const handlePrintOrder = () => {
    document.body.classList.add('print-mode')
    setTimeout(() => {
      window.print()
      // Remove 'print-mode' class after printing
      document.body.classList.remove('print-mode')
    }, 100)
  }

  return (
    <>
      {loggedIn && !props?.orderId && <BreadCrumb></BreadCrumb>}
      <Box
        pl={{ base: 4, md: props?.orderId ? 4 : 10 }}
        pr={{ base: 4, md: props?.orderId ? 4 : '100px' }}
        pt={{ base: props?.orderId ? 2 : 10, md: props?.orderId ? '0px' : 12 }}
        pb={{ base: 10, md: props?.orderId ? '0px' : '80px' }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={{ base: 6, md: 10 }}
          className="print-only"
        >
          {props?.orderId && (
            <Link onClick={props.backToHistoryPage} fontSize={'sm'} color={'var(--semantic-text-primary, #15191C)'}>
              <HStack>
                <ChevronLeftIcon fontSize={'20px'} />
                <Box fontSize={'14px'} textDecoration={'underline'}>
                  {formatMessage({
                    id: 'checkout.success.orderDetails.backToOrderHistory',
                  })}
                </Box>
              </HStack>
            </Link>
          )}

          <Stack gap={4} w={'100%'}>
            <Box>
              <OrderStatusMap orderStatusValue={orderDetails?.rootData?.FulfillmentStatus} />
            </Box>
            <Flex justifyContent={'space-between'}>
              <Heading fontSize="28px" fontWeight={700}>
                Order #{orderId ?? orderId}
              </Heading>
              <HStack cursor={'pointer'}>
                <IoPrint />{' '}
                <Text fontSize={'sm'} onClick={() => handlePrintOrder()} textDecoration={'underline'}>
                  Print
                </Text>
              </HStack>
            </Flex>

            <Box display={'flex'} flexDirection={'column'}>
              {orderDetails?.rootData?.CreatedTimestamp ? <OrderPlacedDate /> : null}
              {orderDetails ? getQuantity() : null}
            </Box>

            {orderDetails?.rootData?.OrderToken && orderDetails?.rootData?.OrgId && isReturnItemsEnable && (
              <Link target="_blank" width={'130px'} _hover={{ textDecoration: 'none' }} href={createReturlUrl()}>
                <Button size={'md'} variant="outline" colorScheme="blue">
                  Return Items
                </Button>
              </Link>
            )}
          </Stack>
          {!!orderDetails ? (
            <>
              <Grid
                templateColumns={isMobile ? '1fr' : '1fr 1fr 1fr'}
                gap={{ base: 4, md: 3 }}
                py={{ base: 6, md: 10 }}
                px={{ base: 4, md: 6 }}
                bgColor="var(--semantic-surface-muted, #F3F4F6)"
                display="flex"
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems="flex-start"
                alignSelf="stretch"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  flex="1 0 0"
                  alignSelf="stretch"
                  gap={3}
                  borderBottom={{ base: '1px solid var(--semantic-surface-border, #E1E5E8)', md: 'none' }}
                  pb={{ base: 6, md: 0 }}
                >
                  <Heading fontWeight={600} fontSize="16px" color={'text-muted'}>
                    Billed To
                  </Heading>
                  <BillingAddress billingAddress={billingAddress} />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  flex="1 0 0"
                  alignSelf="stretch"
                  gap={3}
                  pb={{ base: 6, md: 0 }}
                  borderBottom={{ base: '1px solid var(--semantic-surface-border, #E1E5E8)', md: 'none' }}
                >
                  <Heading mb={1} color={'text-muted'} fontWeight={600} fontSize="16px">
                    Payment Method
                  </Heading>
                  {orderDetails?.paymentDetails?.map((item) => getPaymentDetails(item))}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  flex="1 0 0"
                  alignSelf="stretch"
                  gap={3}
                >
                  <Heading color={'text-muted'} fontWeight={600} fontSize="16px">
                    Summary of Charges
                  </Heading>
                  <Stack width={'100%'} gap={2}>
                    <SummaryOfCharge
                      title={'Subtotal'}
                      value={CurrencyHelpers.formatForCurrency(orderDetails?.orderSummary?.subTotal * 100)}
                    />

                    {orderDetails?.orderSummary?.Shipping > 0 ? (
                      <SummaryOfCharge
                        title={'Shipping'}
                        value={CurrencyHelpers.formatForCurrency(
                          orderDetails?.orderSummary?.Shipping ? orderDetails?.orderSummary?.Shipping * 100 : 0,
                        )}
                      />
                    ) : (
                      <SummaryOfCharge title={'Shipping'} value={'Free'} />
                    )}

                    <SummaryLineItem
                      label={formatMessage({ id: 'cart.summary.estimatedHandlingFees' })}
                      value={CurrencyHelpers.formatForCurrency(
                        orderDetails?.orderSummary?.Handling ? orderDetails?.orderSummary?.Handling * 100 : 0,
                      )}
                      textStyle="body-75"
                      showToolTip
                      toolTipMessage={formatMessage({ id: 'cart.summary.estimatedHandlingFees.toolTipMessage' })}
                    />

                    <SummaryOfCharge
                      title={'Tax'}
                      value={CurrencyHelpers.formatForCurrency(orderDetails?.orderSummary?.tax * 100)}
                    />

                    <Flex width="100%" mb={2}>
                      <Box flex={1}>
                        <Text textAlign="left" fontSize={'sm'}>
                          Promo Code
                        </Text>
                      </Box>
                      <Box>
                        <Text textAlign="right" fontSize={'sm'} color={'danger.600'}>
                          {CurrencyHelpers.formatForCurrency(orderDetails?.orderSummary?.discount * 100)}
                        </Text>
                      </Box>
                    </Flex>

                    <SummaryOfCharge
                      title={'Order Total'}
                      value={CurrencyHelpers.formatForCurrency(orderDetails?.orderSummary?.total * 100)}
                      {...{
                        paddingTop: 'var(--sizes-4, 16px)',
                        borderTop: '1px solid var(--semantic-surface-border, #DCDFE4)',
                        fontWeight: '700',
                      }}
                    />
                  </Stack>
                </Box>
              </Grid>

              {orderDetails?.ShipToAddress?.length > 0 && (
                <Box display="flex" flexDirection="column" alignItems=" flex-start" width="100%">
                  <DeliveryTypeTitle
                    deliveryTypeIcon={<BsTruck />}
                    deliveryType={'Shipping'}
                    deliveryCity={``}
                    deliveryTypeItemLength={orderDetails?.ShipToAddress?.length}
                  />

                  <Stack borderColor={'primary.200'} borderWidth={'1px'} w={'100%'} padding={4} gap={4}>
                    {orderDetails?.ShipToAddress?.map((item: any, index) => {
                      const deliveryType = item.deliveryType
                      return (
                        <OrderItems
                          key={index}
                          orderSummary={orderDetails?.orderSummary}
                          id={`${deliveryType}_${index}`}
                          item={item}
                        ></OrderItems>
                      )
                    })}
                  </Stack>
                </Box>
              )}
              {orderDetails?.PickUpAtStore?.length > 0 && (
                <>
                  {getItemsGroupByStores()?.map(([city, items]: [string, any[]], index) => (
                    <Box key={index} display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                      <DeliveryTypeTitle
                        key={index}
                        deliveryTypeIcon={<BsShop />}
                        deliveryType={`Store Pick Up at`}
                        deliveryTypeItemLength={items.length}
                        deliveryCity={`${city.toLowerCase()}`}
                      />
                      <Stack borderColor={'primary.200'} borderWidth={'1px'} w={'100%'} padding={4} gap={4}>
                        {items.map((item, index) => (
                          <OrderItems
                            key={`${item.deliveryType}_${index}`}
                            orderSummary={orderDetails?.orderSummary}
                            id={`${item.deliveryType}_${index}`}
                            item={item}
                          />
                        ))}
                      </Stack>
                    </Box>
                  ))}
                </>
              )}
              <CustomerDeliveryInfo />
            </>
          ) : (
            <Center h="100px">
              <Button isLoading loadingText="Loading.." colorScheme="black" variant="link" spinnerPlacement="start">
                Submit
              </Button>
            </Center>
          )}
        </Box>
      </Box>
      <style jsx global>{`
        @media print {
          /* Hide everything by default */
          body * {
            visibility: hidden;
          }
          /* Show only the print-only container during print mode */
          body.print-mode .print-only,
          body.print-mode .print-only * {
            visibility: visible;
          }
          /* Ensure the printed content is placed correctly */
          body.print-mode .print-only {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      `}</style>
    </>
  )
}

export default OrderDetails

interface DeliveryTypeTitleProps {
  deliveryTypeIcon: JSX.Element
  deliveryType: string
  deliveryCity?: string
  deliveryTypeItemLength: number
}

const DeliveryTypeTitle: React.FC<DeliveryTypeTitleProps> = ({
  deliveryTypeIcon,
  deliveryType,
  deliveryCity,
  deliveryTypeItemLength,
}) => {
  return (
    <Flex fontSize="16px" gap={2} w={'100%'} p={4} bgColor="var(--semantic-surface-muted, #F3F4F6)">
      {deliveryTypeIcon}
      <Heading fontWeight={'600'} fontSize="16px" color={'var(--semantic-text-primary, #15191C)'}>
        {deliveryType}
        <Text as={'span'} textTransform={'capitalize'}>
          {` ${deliveryCity}`}
        </Text>
      </Heading>
      <Text color={'text-muted'}>{`(${deliveryTypeItemLength} Items)`}</Text>
    </Flex>
  )
}
const getPhoneformat = (phoneNumber: any) => {
  if (phoneNumber) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }
}
const BillingAddress = ({ billingAddress }) => {
  return (
    <Box fontSize={'14px'}>
      <Text>
        {billingAddress?.fname} {billingAddress?.lname}
      </Text>
      <Text> {billingAddress?.addrLine1}, </Text>
      <Text>
        {billingAddress?.city}, {billingAddress?.state} {billingAddress?.zipcode}
      </Text>
      <Text> {getPhoneformat(billingAddress?.phoneNumber)} </Text>
    </Box>
  )
}

const CustomerDeliveryInfo = () => {
  return (
    <UnorderedList color={'var(--semantic-text-muted, #76797E)'}>
      <ListItem fontSize={'14px'}>Please allow 3-6 business days for your order to appear as 'shipped.'</ListItem>
      <ListItem fontSize={'14px'}>
        UPS tracking information will be updated within 1-4 days once the order is processed through one of the UPS
        hubs.
      </ListItem>
      <ListItem fontSize={'14px'}>
        Within the continental U.S., you can expect delivery of in-stock orders within 3-7 business days (Mon-Fri,
        excluding holidays) after receiving credit authorization (international orders cannot be tracked online).
      </ListItem>
      <ListItem fontSize={'14px'}>
        Within AK, HI, APO, FPO, and U.S. Territories, allow 20 business days for delivery by the U.S. Postal Service.
      </ListItem>
      <ListItem fontSize={'14px'}>
        For orders shipped directly by our vendors ('drop ship'), add additional delivery time to in-stock orders.
        Additional delivery times vary by item.
      </ListItem>
      <ListItem fontSize={'14px'}>Allow 1-2 extra business days for iterations.</ListItem>
    </UnorderedList>
  )
}

interface SummaryOfChargeProps {
  title: string
  value: string
}

const SummaryOfCharge = ({ title, value, ...props }: SummaryOfChargeProps) => {
  return (
    <Flex width="100%" {...props}>
      <Box flex={1}>
        <Text textAlign="left" fontSize={title == 'Order Total' ? '16px' : 'sm'}>
          {title}
        </Text>
      </Box>
      <Box>
        <Text textAlign="right" fontSize={title == 'Order Total' ? '16px' : 'sm'}>
          {value}
        </Text>
      </Box>
    </Flex>
  )
}

interface InfoSectionContentProps {
  content: string[]
  textProps?: TextProps
}

interface InfoSectionTitleProps extends TextProps {
  title: string
}

const InfoSectionHOC: ({ children }: { children: any }) => JSX.Element = ({ children }) => {
  return <Box>{children}</Box>
}
const InfoSectionTitle = ({ title, ...textProps }: InfoSectionTitleProps) => {
  return (
    <Text fontSize="sm" fontWeight="extrabold" mb={4} {...textProps}>
      {title}
    </Text>
  )
}

const InfoSectionContent = ({ content, textProps }: InfoSectionContentProps) => {
  return (
    <>
      {content.map((c, index) => (
        <Text key={index} fontSize="sm" {...textProps}>
          {c}
        </Text>
      ))}
    </>
  )
}

export interface InfoSectionHOCProps {
  ({ children }: any): JSX.Element
  Title: ({ title }: InfoSectionTitleProps) => JSX.Element
  Content: ({ content }: InfoSectionContentProps) => JSX.Element
}

export const InfoSectionCustom: InfoSectionHOCProps = Object.assign(InfoSectionHOC, {
  Title: InfoSectionTitle,
  Content: InfoSectionContent,
})

interface OrderStatusMapProps {
  orderStatusValue: string
}

export const OrderStatusMap = (props: OrderStatusMapProps) => {
  const orderStatus = props.orderStatusValue
  if (
    orderStatus == 'In Process' ||
    orderStatus == 'Allocated' ||
    orderStatus == 'Partially Allocated' ||
    orderStatus == 'Picked' ||
    orderStatus == 'Packed' ||
    orderStatus == 'Released' ||
    orderStatus == 'Partially Released' ||
    orderStatus == 'Back Ordered'
  ) {
    return (
      <Tag
        background={'var(--semantic-ui-success-light, #D9FADB)'}
        fontSize={'sm'}
        px={'6px'}
        py={'4px'}
        display={'inline'}
        borderRadius={'4px'}
        fontWeight={'bold'}
        letterSpacing={'0.04em'}
        color={'var(--semantic-ui-success-med, #38A169)'}
      >
        IN PROGRESS
      </Tag>
    )
  } else if (orderStatus == 'Canceled') {
    return (
      <Tag
        background={'var(--semantic-ui-danger-light, #FDE5D8)'}
        fontSize={'sm'}
        color={'var(--semantic-ui-danger-med, #C42D3A)'}
        px={'6px'}
        letterSpacing={'0.04em'}
        py={'4px'}
        display={'inline'}
        borderRadius={'4px'}
        fontWeight={'bold'}
      >
        CANCELED
      </Tag>
    )
  } else if (orderStatus == 'Fulfilled' || orderStatus == 'Shipped') {
    return (
      <Tag
        background={'var(--semantic-ui-info-light, #D6F1FC)'}
        fontSize={'sm'}
        color={'var(--semantic-ui-info-dark, #184B94)'}
        px={'6px'}
        py={'4px'}
        letterSpacing={'0.04em'}
        display={'inline'}
        borderRadius={'4px'}
        fontWeight={'bold'}
      >
        SHIPPED
      </Tag>
    )
  } else if (
    orderStatus == 'Return' ||
    orderStatus == 'Received' ||
    orderStatus == 'Pending Return' ||
    orderStatus == 'Pending Approval' ||
    orderStatus == 'Returned' ||
    orderStatus == 'Partially Returned' ||
    orderStatus == 'Partially Pending Return'
  ) {
    return (
      <Tag
        background={'var(--semantic-surface-highlight, #E1E5E8)'}
        fontSize={'sm'}
        color={'var(--semantic-brand-secondary, #606F80)'}
        px={'6px'}
        py={'4px'}
        letterSpacing={'0.04em'}
        display={'inline'}
        borderRadius={'4px'}
        fontWeight={'bold'}
      >
        RETURN
      </Tag>
    )
  } else if (orderStatus == 'Partially Fulfilled') {
    return (
      <Tag
        background={'var(--semantic-ui-info-light, #D6F1FC)'}
        fontSize={'sm'}
        color={'var(--semantic-ui-info-dark, #184B94)'}
        px={'6px'}
        py={'4px'}
        letterSpacing={'0.04em'}
        display={'inline'}
        borderRadius={'4px'}
        fontWeight={'bold'}
      >
        Partially Fulfilled
      </Tag>
    )
  } else if (orderStatus == 'Partially In Process') {
    return (
      <Tag
        background={'var(--semantic-ui-info-light, #D6F1FC)'}
        fontSize={'sm'}
        color={'var(--semantic-ui-info-dark, #184B94)'}
        px={'6px'}
        py={'4px'}
        letterSpacing={'0.04em'}
        display={'inline'}
        borderRadius={'4px'}
        fontWeight={'bold'}
      >
        Partially Back Ordered
      </Tag>
    )
  } else if (orderStatus == 'Partially Picked') {
    return (
      <Tag
        background={'var(--semantic-ui-info-light, #D6F1FC)'}
        fontSize={'sm'}
        color={'var(--semantic-ui-info-dark, #184B94)'}
        px={'6px'}
        py={'4px'}
        letterSpacing={'0.04em'}
        display={'inline'}
        borderRadius={'4px'}
        fontWeight={'bold'}
      >
        Partially Picked
      </Tag>
    )
  } else {
    return (
      <Tag
        background={'var(--semantic-surface-highlight, #E1E5E8)'}
        fontSize={'sm'}
        color={'var(--semantic-brand-secondary, #606F80)'}
        px={'6px'}
        py={'4px'}
        letterSpacing={'0.04em'}
        display={'inline'}
        borderRadius={'4px'}
        fontWeight={'bold'}
      >
        {orderStatus}
      </Tag>
    )
  }
}
